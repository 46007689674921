import React, {useState, useEffect} from 'react'
import {useCookies} from 'react-cookie'
import {Link} from 'gatsby'
import SideBar from '../../SideBar'
import Image from 'gatsby-image'
import PortableText from '../../serializers/portableText'

import LinkIcon from '../../../lib/LinkIcon'
import styles from './business.module.css'

export default function Business ({title, slug, _rawBody, links, location, businessContact, logo, sideBar}) {
  //  TODO: COOKIE
  const [cookies] = useCookies(['DiningDate', 'RetailDate', 'EntAndRecDate', 'BizDate'])
  const [bizDirURL, setBizDirURL] = useState(null)

  // TODO: Cookie
  useEffect(() => {
    //
    const DirDates = {Dining: cookies['DiningDate'], Retail: cookies['RetailDate'], EntAndRec: cookies['EntAndRecDate'], Biz: cookies['BizDate']}
    console.log({DirDates})
    const filteredDir = Object.fromEntries(Object.entries(DirDates).filter(([, v]) => !!v))
    const sortedDir = Object.keys(filteredDir).sort(function (a, b) { return parseInt(DirDates[b]) - parseInt(DirDates[a]) })
    console.log(sortedDir[0])

    switch (sortedDir[0]) {
      case 'Dining':
        console.log('Dining')
        setBizDirURL('/dining-directory/')
        break
      case 'Retail':
        console.log('Retail')
        setBizDirURL('/retail-directory/')
        break
      case 'EntAndRec':
        console.log('EntAndRec')
        setBizDirURL('/entertainment-directory/')
        break
      case 'Biz':
        console.log('Biz')
        setBizDirURL('/business-directory/')
        break
      default:
        console.log('No dda directory referal')
    }
  }, [])

  const bodyCheck = (_rawBody && _rawBody[0] && _rawBody[0].children[0] && _rawBody[0].children[0].text.length > 1)
  return (
    <div className={styles.mainContentBiz}>

      <div className={styles.innerMainContentBiz}>
        {bodyCheck ? <PortableText blocks={_rawBody} /> : (<>
          <h4>Is this your business?</h4>
          <p className={styles.claim}>
            Claim your listing on the Greeley DDA site by sending a brief write-up and logo through our <a href='/contact'>contact form</a>!
          </p>
        </>) }

      </div>

      <div className={styles.sideBarFirst}>
        <div className={styles.left}>
          {/* TODO: Cookie */}
          {bizDirURL && <div style={{display: 'flex', justifyItems: 'center', marginBottom: '.75rem'}}><LinkIcon type='left' /><Link to={bizDirURL} className={styles.callLink} style={{marginLeft: '.5rem'}}>Back to Results</Link></div>}

          {/* logo */}
          {logo && logo.asset && logo.asset.fluid && (
            <Image fluid={logo.asset.fluid} alt='logo' style={{maxWidth: '150px', margin: '0 auto'}} />
          )}

        </div>
        <div className={styles.right}>
          {/* address and contact info */}

          <address>
            <div className='vcard'>

              <div className='org'>
                <h3 className='sr-only'> <Link
                  to={`/business-directory/${slug}`}>{title}</Link></h3>
              </div>

              <div className='adr'>
                <div className='street-address'>{location.streetAddress}</div><span className='locality'>{location.city},</span> <span
                  className='region'>{location.state}</span> <span className='postal-code' />{location.zipcode}<span className='country-name sr-only'>United States</span>
              </div>

              <div className={styles.linksWrapper}>

                {businessContact && businessContact && businessContact.contactPhone && (
                  <div className={styles.linkWrapper}><LinkIcon type='call' /> <a href={`tel:${businessContact.contactPhone}`} target='_blank' rel='noopener noreferrer' title={`Call ${title}`} className={styles.callLink}>{businessContact.contactPhone}</a></div>
                )}

                <div className={styles.linkWrapper}><LinkIcon type='map' /> <a href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURI(location.streetAddress)},${encodeURI(location.city)},${encodeURI(location.state)}`} target='_blank' rel='noopener noreferrer'title={`Map and Directions to ${title}`}className={styles.mapLink}>map &amp; directions</a></div>
              </div>
            </div>
          </address>

        </div>
        <div className={styles.full}>

          {/* icons */}
          {links.length > 0 && (
            <>
              <p className={styles.WeblinksTitle}>Website Links:</p>
              <div className={styles.bizIcons}>

                {links.map(link => (
                  <a href={link.link} key={link._key} aria-label={`${title} ${link.linkType} link`} target='_blank' rel='noopener noreferrer' title={`${title} ${link.linkType} link`}><LinkIcon type={link.linkType} /></a>
                ))}
              </div>
            </>
          )}

        </div>
      </div>
      {sideBar && (<SideBar data={sideBar} barClass />)}
    </div>
  )
}
