import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import Business from './Business'
import Footer from '../../Footer'

// import SideBar from '../../SideBar'
// import Image from 'gatsby-image'
// import PortableText from '../../serializers/portableText'

import Container from '../../container'
// import LinkIcon from '../../../lib/LinkIcon'
import styles from '../page.module.css'

const Page = ({title, _rawBody, links, location, businessContact, logo, slug}) => {
  const data = useStaticQuery(graphql`
  {
    sanitySideBar(id: {eq: "a5a04730-4107-510d-8c16-a054f2aa2c31"}) {
      title
      links {
        _key
        title
        siteLink
      }
    }
  }
  `)
  // console.log({location})
  // console.log({businessContact})
  return (
    <>

      <article className={styles.root}>

        <Container>
          <div className={styles.pageTitleWrapper}>
            <h1 className={styles.pageTitle}>{title}</h1>
          </div>

          <Business title={title} slug={slug} _rawBody={_rawBody} links={links} location={location} businessContact={businessContact} logo={logo} sideBar={data.sanitySideBar} />

        </Container>
      </article>

      <Footer topBorder />

    </>
  )
}
export default Page
